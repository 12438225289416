import * as pdfjsLib from "pdfjs-dist/build/pdf.mjs";

pdfjsLib.GlobalWorkerOptions.workerSrc = window.pdfWorkerSrc;

customElements.define(
  "pdf-viewer-element",
  class extends HTMLElement {
    static observedAttributes = ["url"];
    constructor() {
      super();
    }

    connectedCallback() {
      this.classList.add("v3__skeleton");
      this.classList.add("v3__skeleton--standalone");
    }

    attributeChangedCallback(_attribute, _oldValue, newValue) {
      const viewer = this;
      const url = newValue;
      const scale = 1;
      const pdfViewerElement = this;
      let loadedPages = 0;

      pdfjsLib.getDocument(url).promise.then(function (pdf) {
        for (let page = 1; page <= pdf.numPages; page++) {
          const canvas = document.createElement("canvas");
          canvas.className = "pdf-page-canvas";
          viewer.appendChild(canvas);
          renderPage(pdf.numPages, page, canvas, pdf);
        }
      });

      function renderPage(totalPages, pageNumber, canvas, pdf) {
        pdf
          .getPage(pageNumber)
          .then(function (page) {
            const viewport = page.getViewport({ scale: scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            page.render({
              canvasContext: canvas.getContext("2d"),
              viewport: viewport,
            });
          })
          .then(function () {
            loadedPages++;
            if (loadedPages == totalPages) {
              pdfViewerElement.setAttribute("loaded", "true");
              pdfViewerElement.classList.remove("v3__skeleton");
              pdfViewerElement.classList.remove("v3__skeleton--standalone");
            }
          });
      }
    }
  },
);
